export default {
    "test": "DE Test",
    "login": {
        "username": "Benutzername",
        "password": "Passwort",
        "login": "Anmelden",
        "stayLogged": "angemelder bleiben",
        "a11y": "Barrierefreiheit",
        "language": "Sprache",
        "timezone": "Zeitzone"
    },
    "form": {
        "validation": {
            "errors": "Fehler",
            "formSubmissionFailed": "Formularübermittlung fehlgeschlagen.",
            "isRequired": " ist Pflichtfeld.",
            "uniformFieldErrorMessageP1": "Das Feld",
            "uniformFieldErrorMessageP2": "ist leer, es ist ein Pflichtfeld und muss ausgefüllt werden.",
        }
    },
    "profile": {
        "settings": "Einstellungen",
        "help": "Hilfe",
        "logout": "Abmelden",
        "menu": "Menü öffnen",
        "currentUser": "Konto des aktuellen Benutzers"
    },
    "pages": {
        "home": "Mein Armaturenbrett",
        "calendar": "Kalender",
        "notifications": "Benachrichtigungen",
        "favorites": "Favoriten",
        "contacts": "Kontakte",
        "companies": "Firmen",
        "activity": "Aktivität",
        "task": "TasAufgabek",
        "customerReaction": "Kundenreaktion",
        "addCustomerReaction": "Kundenreaktion hinzufügen",
        "detailCustomerReaction": "Details zur Kundenreaktion"
    },
    "dragableBox": {
        "more": "Mehr",
        "config": "Konfig",
        "share": "Freigeben",
        "publish": "Veröffentlichen",
        "settings": "Einstellungen",
        "close": "Schließen",
    },
    "customerReaction": {
        "add": "Neuanlage",
        "open": "Öffnen",
        "edit": "Bearbeiten",
        "delete": "Löschen",
        "preview": "Vorschau",
        "filter": "Filter",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "back": "Zurück",
        "createDashlet": "Dashlet erzeugen",
        "help": "Hilfe anzeigen",
        "favorite": "Favoriten",
        "communication": "Kommunikation",
        "furtherInformation": "Weitere Informationen",
        "more": "Mehr",
        "profilePictureOf": "Profilbild von",
        "newActivity": "Neue Aktivität",
        "editDefaults": "Vorgaben bearbeiten",
        "newOffer": "Neues Angebot",
        "newTask": "Neue Aufgabe",
        "writeEmail": "E-Mail schreiben",
        "newAppointment": "Neuer Termin",
        "newLetter": "Neuer Brief",
        "newVisitRecommend": "Neue Besuchsempfehlung",
        "newWeekplanentry": "Neuer Wochenplaneintrag",
        "openAdminView": "Admin-Ansicht öffnen",
        "serialActions": "Serielle Aktionen",
        "addToCampaign": "Zur Kampagne hinzufügen",
        "addToBulkmail": "Zu Bulkmail hinzufügen",
        "addToSerialLetter": "Zum Serienbrief hinzufügen",
        "export": "Export",
        "startMarketingMailing": "Marketing-Mailing starten",
        "tab": "Tab",
        "typeOfIssue": "Art der Ausgabe",
        "detail": "Einzelheiten",
        "field": {
            "picture": "Bild",
            "customerReactionCode": "Kundenreaktionscode",
            "firstName": "Vorname",
            "lastName": "Nachname",
            "dateOfReceipt": "Eingangsdatum",
            "circleOfPeople": "Kreis von Menschen",
            "customerReactionType": "Art der Kundenreaktion",
            "customerReactionStatus": "Status der Kundenreaktion",
            "wantAnswer": "Antwort wollen",
            "zipCode": "Postleitzahl",
            "place": "Ort",
            "organisationId": "Organisations-ID"
        },
        "contactManagement": {
            "contactManagement": "Verwaltung der Kontakte",
            "contacts": "Kontakte",
            "companies": "Unternehmen",
            "activity": "Tätigkeit",
            "task": "Aufgabe",
            "sales": "Vertrieb",
            "salesDashboard": "Dashboard Vertrieb",
            "salesProject": "Verkaufsprojekt",
            "offer": "Angebot",
            "receipt": "Quittung",
            "contract": "Vertrag",
            "product": "Produkt",
            "prices": "Preise",
            "conversionRate": "Umrechnungskurs",
            "turnover": "Umsatz"
        }
    }
}