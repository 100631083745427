import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

export function PageLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
