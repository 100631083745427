import { ReactNode, Suspense } from 'react';
import { PageLoader } from './PageLoader';

type Props = {
  children: ReactNode;
};

export function LoaderSuspense({ children }: Props) {
  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
}
