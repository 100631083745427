import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { A11yStateProvider } from './hooks/useA11yState';
import { A11yThemeProvider } from './hooks/useA11yTheme';
import { AuthProvider } from './hooks/useAuth';

const globalStyles = (
  <GlobalStyles
    styles={{
      'html, body, #root': { height: '100%' },
      address: { fontStyle: 'normal' },
      '*': {
        overscrollBehaviorY: 'none',
      },
      // 'dd, dt': {
      //   lineHeight: '2 !important', // TODO ugly
      // },
    }}
  />
);

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <A11yStateProvider>
        <A11yThemeProvider>
          <CssBaseline />

          {globalStyles}

          <App />
        </A11yThemeProvider>
      </A11yStateProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.querySelector('#root'),
);
