export default {
    "test": "EN Test",
    "login": {
        "username": "User name",
        "password": "Password",
        "login": "Log in",
        "stayLogged": "Stay logged in",
        "a11y": "Accessibility",
        "language": "Language",
        "timezone": "Time zone"
    },
    "form": {
        "validation": {
            "errors": "errors",
            "formSubmissionFailed": "Form submission failed.",
            "isRequired": " is required field.",
            "uniformFieldErrorMessageP1": "The",
            "uniformFieldErrorMessageP2": "field is empty, it is a required field and must be filled in.",
        }
    },
    "profile": {
        "settings": "Settings",
        "help": "Help",
        "logout": "Log out",
        "menu": "Open menu",
        "currentUser": "Account of current user"
    },
    "pages": {
        "home": "My Dashboard",
        "calendar": "Calendar",
        "notifications": "Notifications",
        "favorites": "Favorites",
        "contacts": "Contacts",
        "companies": "Companies",
        "activity": "Activity",
        "task": "Task",
        "customerReaction": "Customer Reaction",
        "addCustomerReaction": "Add Customer Reaction",
        "detailCustomerReaction": "Customer Reaction Detail"
    },
    "dragableBox": {
        "more": "More",
        "config": "Config",
        "share": "Share",
        "publish": "Publish",
        "settings": "Settings",
        "close": "Close",
    },
    "customerReaction": {
        "add": "New",
        "open": "Open",
        "edit": "Edit",
        "delete": "Delete",
        "preview": "Preview",
        "filter": "Filter",
        "save": "Save",
        "cancel": "Cancel",
        "back": "Back",
        "createDashlet": "Create dashlet",
        "help": "View help",
        "favorite": "Favorite",
        "communication": "Communication",
        "furtherInformation": "Further information",
        "more": "More",
        "profilePictureOf": "Profile picture of",
        "newActivity": "New activity",
        "editDefaults": "Edit defaults",
        "newOffer": "New offer",
        "newTask": "New task",
        "writeEmail": "Write email",
        "newAppointment": "New appointment",
        "newLetter": "New letter",
        "newVisitRecommend": "New Visit Recommendation",
        "newWeekplanentry": "New Weekplanentry",
        "openAdminView": "Open admin view",
        "serialActions": "Serial Actions",
        "addToCampaign": "Add to Campaign",
        "addToBulkmail": "Add to Bulkmail",
        "addToSerialLetter": "Add to serial letter",
        "export": "Export",
        "startMarketingMailing": "Start marketing mailing",
        "tab": "Tab",
        "typeOfIssue": "Type of issue",
        "detail": "Detail",
        "field": {
            "picture": "Picture",
            "customerReactionCode": "Customer Reaction Code",
            "firstName": "First Name",
            "lastName": "Last Name",
            "dateOfReceipt": "Date of receipt",
            "circleOfPeople": "Circle of people",
            "customerReactionType": "Customer Reaction Type",
            "customerReactionStatus": "Customer Reaction Status",
            "wantAnswer": "Want Answer",
            "zipCode": "Zip Code",
            "place": "Place",
            "organisationId": "Organisation ID"
        },
        "contactManagement": {
            "contactManagement": "Contact management",
            "contacts": "Contacts",
            "companies": "Companies",
            "activity": "Activity",
            "task": "Task",
            "sales": "Sales",
            "salesDashboard": "Sales Dashboard",
            "salesProject": "Sales Project",
            "offer": "Offer",
            "receipt": "Receipt",
            "contract": "Contract",
            "product": "Product",
            "prices": "Prices",
            "conversionRate": "Conversion Rate",
            "turnover": "Turnover"
        }
    }

}