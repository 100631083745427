import { grey } from '@mui/material/colors';
import { createTheme, darken, ThemeProvider } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';
import { useA11yState } from './useA11yState';
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

type Props = {
  variant?: 'main' | 'login' | 'sidebar';
  children: ReactNode;
};

export function A11yThemeProvider({ variant = 'main', children }: Props) {
  const { value: a11y } = useA11yState();

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiDataGrid: {
            styleOverrides: {
              columnHeader: {
                '&:focus .MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '&:focus .MuiDataGrid-menuIcon': {
                  visibility: 'visible',
                  width: 'auto',
                },
                '&:focus .MuiDataGrid-sortIcon, &:focus .MuiDataGrid-menuIcon': {
                  opacity: '0.75 !important',
                  color: '#1a1a1a',
                },
                '&:focus.MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon, &:focus.MuiDataGrid-columnHeader--sorted .MuiDataGrid-menuIcon': {
                  opacity: '1 !important',
                }
              }
            }
          },
          MuiTab: {
            styleOverrides: {
              root: {
                textTransform: 'initial',
              }
            }
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                '&.MuiTypography-overline': {
                  textTransform: 'initial',
                }
              }
            }
          },
          MuiButton: {
            defaultProps: {
              variant: 'contained',
              size: 'medium',
            },
            styleOverrides: {
              root: {
                textTransform: 'initial',
                '&.Mui-disabled': {
                  color: a11y ? '#1A1A1A' : '#666'
                }
              }
            }
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                '& .MuiTouchRipple-child': {
                  opacity: a11y ? '0.2 !important' : '0.3 !important'
                }
              }
            }
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                '& input[type="date"]': {
                  cursor: 'text',
                  opacity: 0,
                  transition: '0.2s all'
                },
                '&.Mui-focused input[type="date"], & input[type="date"]:not([value=""])': {
                  opacity: 1,
                  transition: '0.2s all'
                }
              },
            }
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                transform: 'translate(12px, 18px) scale(1)'
              },
              shrink: {
                transform: 'translate(12px, 7px) scale(0.75)'
              },
            }
          },
          MuiSelect: {
            defaultProps: {
              variant: 'filled', // Outlined variant throws warnings/errors in axe-devtool reports (chrome extension). Edited filled variant passes.
            },
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                color: a11y ? '#1A1A1A' : '#757575',
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              variant: 'filled', // Outlined variant throws warnings/errors in axe-devtool reports (chrome extension). Edited filled variant passes.
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                '& .MuiInputLabel-root': {	
                  color: a11y ? '#262626' : '#666666',	
                  '&.Mui-focused': {	
                    color: a11y ? '#262626' : '#4499DC'	
                  },	
                  '&.Mui-error': {
                    color: a11y ? '#d32f2f' : '#d32f2f',	
                  }
                },	
                '& .MuiFilledInput-root': {	
                  backgroundColor: 'white',	
                  border: `1px solid ${a11y ? '#262626' : '#666666'}`,	
                  borderRadius: '4px',	
                  '&.Mui-focused': {	
                    backgroundColor: 'white',	
                  },	
                  '&.Mui-error': {
                    color: a11y ? '#d32f2f' : '#d32f2f',
                    border: `1px solid ${a11y ? '#d32f2f' : '#d32f2f'}`,	
                  },
                  '&:hover': {	
                    backgroundColor: 'white',	
                    border: `1px solid ${a11y ? '#0d0d0d' : '#666666'}`,	
                  },	
                }, 
                '& .MuiFilledInput-input': {
                  '&:focus': {	
                    backgroundColor: 'transparent',	
                  },	
                },	
                '& .MuiFilledInput-root::before': {	
                  borderBottom: '1px solid transparent',	
                  '&.Mui-focused': {	
                    borderColor: 'transparent'	
                  },	
                },	
                '& .MuiFilledInput-root::after': {	
                  borderBottom: '1px solid transparent'	
                },	
                '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {	
                  borderBottom: '1px solid transparent'	
                }
              }
            }
          },
          ...(variant === 'sidebar'
            ? {
                MuiListItemButton: {
                  styleOverrides: {
                    root: {
                      '&:hover': {
                        backgroundColor: a11y ? '#4d4d4d' : '#797979'
                      },
                      '& .MuiTouchRipple-child': {
                        opacity: a11y ? '0.2 !important' : '0.3 !important'
                      }
                    }
                  }
                },
                MuiListItemText: {
                  styleOverrides: {
                    primary: {
                      '.Mui-selected &': {
                        color: a11y ? '#000' : '#FFF'
                      },
                    },
                  },
                },
                MuiListItemIcon: {
                  styleOverrides: {
                    root: {
                      color: '#FFF',
                      '.Mui-selected &': {
                        color: a11y ? '#000' : '#FFF'
                      },
                    },
                  },
                },
                MuiButton: {
                  styleOverrides: {
                    root: {
                      ':hover': {
                        backgroundColor: a11y ? darken('#E31F4E', 1) : 'rgba(238, 238, 238, 0.5)'
                      }
                    }
                  }
                }
              }
            : {}),
        },
        palette:
          variant === 'login' ? { 
            mode: 'dark',
          } : variant === 'sidebar' ? {
            background: {
              paper: '#333333',
            },
            primary: {
              main: '#eee',
            },
            secondary: {
              main: '#E31F4E',
            },
            text: {
              primary: '#fff',
              secondary: '#fff',
            },
            action: {
              active: '#fff',
              selected: a11y ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.5)',
              selectedOpacity: a11y ? 1 : 0.5,
              focus: 'rgba(192, 192, 192, 0.5)',
              focusOpacity: 0.5,
              hover: 'rgba(192, 192, 192, 0.5)',
              hoverOpacity: 0.5,
            },
          }
          : /* variant === 'main' */ {
            background: {
              default: grey[200],
            },
            primary: {
              main: a11y ? '#14466C' : '#4499DC',
            },
            secondary: {
              main: a11y ? '#0c423d' : '#19857b',
            },
            error: {
              main: a11y ? '#a82424' : '#d32f2f',
            },
            action: {
              // proposed
              // disabled: '#14466C',
              // disabledBackground: '#F3F6FD',
              //
              // mui but stronger
              disabled: '#666',
              // disabledBackground: '#ccc',
            },
            text: {
              primary: '#1a1a1a',
              secondary: a11y ? '#1a1a1a' : '#727272'
            }
          },
      }),
    [a11y, variant],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
