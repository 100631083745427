let auth = "Basic Q3VzdG9tZXJSZWFjdGlvbldlYnNlcnZpY2U6YWRpdG8=";

export async function getCustomerReactions() {
    return await fetch('https://accessibilityrest.ourdevops.io/services/rest/CustomerReaction_webservice', {
        method: 'GET',
        headers: new Headers({
            Authorization: auth,
        }),
    })
    .then(data => data.json())
    .catch(error => console.log('error', error));
}

export function addCustomerReaction(newCustomerReaction) {
    return fetch('https://accessibilityrest.ourdevops.io/services/rest/CustomerReaction_webservice', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: auth,
        }),
        body: JSON.stringify(newCustomerReaction)
    })
    .then(data => data.json())
    .catch(error => console.log('error', error));
}
