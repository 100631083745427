import i18n from "../locales/i18n";
import { tableLangs } from "./constants";

export function getTableLocalizationFromCurrentLanguage() {
    const currentLanguage = i18n.language;
    return tableLangs[currentLanguage];
}

export function completeAllCustomerReactionTableCols(definedCols: Array<any>, crData: Array<string>) {
    const columnsFromData = Object.keys(Object.assign({}, ...crData));
    const columnsDefined = definedCols.map(function(c){
        return(c.field);
    });
    const undefinedColumns = columnsFromData.filter(x => !columnsDefined.includes(x));
    const newColumns = undefinedColumns.map(function(uc){
        return {
            field: uc,
            headerName: uc,
            width: 150, 
            renderCell({row}: {row: any}) {
                return row[uc]
            }
        }
    });
    return newColumns;
}

export function changePageTitle(title:string) {
    document.title = title || "ADITO";
}

export function getUniformFieldErrorMessage(inputLabel:string) {
    return i18n.t('form.validation.uniformFieldErrorMessageP1') + " " + inputLabel + " " + i18n.t('form.validation.uniformFieldErrorMessageP2');
}