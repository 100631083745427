import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router';
import { LoaderSuspense } from './components/LoaderSuspense';
import { useAuth } from './hooks/useAuth';
import './locales/i18n';
import CustomerReactionAddEdit from './pages/customerReaction/CustomerReactionAddEdit';
import CustomerReactionDetailScreen from './pages/customerReaction/CustomerReactionDetailScreen';

const LogIn = lazy(() => import('./pages/logIn/LogIn'));

//const CompaniesScreen = lazy(() => import('./pages/company/CompaniesScreen'));

//const ContactsScreen = lazy(() => import('./pages/contacts/ContactsScreen'));

const CustomerReactionScreen = lazy(() => import('./pages/customerReaction/CustomerReactionsScreen'));

const Home = lazy(() => import('./pages/home/Home'));

const Skeleton = lazy(() => import('./Skeleton'));

export function App() {
  const { value: authenticated } = useAuth();

  return useRoutes([
    authenticated
      ? {
          path: '/',
          element: (
            <LoaderSuspense>
              <Skeleton />
            </LoaderSuspense>
          ),
          children: [
            { path: '/', element: <Home /> },
            { path: '/calendar', element: <main /> },
            { path: '/notifications', element: <main /> },
            { path: '/favorites', element: <main /> },
            //{ path: '/contacts', element: <ContactsScreen /> },
            { path: '/customerReaction', element: <CustomerReactionScreen /> },
            { path: '/customerReaction/add', element: <CustomerReactionAddEdit /> },
            { path: '/customerReaction/detail/:accessibilityId', element: <CustomerReactionDetailScreen /> },
            //{ path: '/companies', element: <CompaniesScreen /> },
            { path: '/activity', element: <main /> },
            { path: '/task', element: <main /> },
          ],
        }
      : {
          path: '/login',
          element: (
            <LoaderSuspense>
              <LogIn />
            </LoaderSuspense>
          ),
        },
    {
      path: '*',
      element: authenticated ? <Navigate to="/" /> : <Navigate to="/login" />,
    },
  ]);
}
