import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { defaultAppLang } from "../utils/constants";
import locales from './index';

const selectedLang = window.localStorage.getItem('lang');

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    resources: locales,
    lng: selectedLang || defaultAppLang,
    fallbackLng: defaultAppLang,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;