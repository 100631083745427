import { Box, Paper } from '@mui/material';
import { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCustomerReactions } from '../../services/customerReactions';
import { CustomerReaction } from '../../types/customerReaction';
import { changePageTitle } from '../../utils/utils';
const CustomerReactionDetail = lazy(() => import('./CustomerReactionDetail'));
const CustomerReactionDetailTabs = lazy(() => import('./CustomerReactionDetailTabs'));

export default CustomerReactionDetailScreen;
export function CustomerReactionDetailScreen() {

    const { t } = useTranslation();
    const { accessibilityId } = useParams();
    const [customerReaction, setCustomerReaction] = useState<CustomerReaction>();

    useEffect(() => {
        getCustomerReactions().then(data => {
            const customerReactionDetailData = data.find((cr: any) => {
                return cr.ACCESSIBILITYID === accessibilityId;
          });
          setCustomerReaction(customerReactionDetailData);
          changePageTitle(t('pages.detailCustomerReaction') + " - " + customerReactionDetailData.FIRSTNAME + " " + customerReactionDetailData.LASTNAME);
        });
    }, [accessibilityId, t]);

    return (
        <>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                display: 'flex',
                p: 2,
                gap: 2,
                flexWrap: { xs: 'wrap', sm: 'wrap', md: 'initial' }
            }}
        >
            <Box
                alignItems="stretch"
                sx={{ width: { xs: 1/1, sm: 1/1, md: 1/2, lg: 2/5, xl: 1/3 }, p: 0, height: '100%', display: 'flex', flexDirection: 'column' }}
            >
                { customerReaction ? (
                    <CustomerReactionDetail customerReaction={customerReaction} />
                ) : null}
            </Box>
            <Box
                sx={{ width: { xs: 1/1, sm: 1/1, md: 1/2, lg: 3/5, xl: 2/3 }, height: '100%', display: 'flex', flexDirection: 'column' }}
                component={Paper}
                elevation={2}
            >
                { customerReaction ? (
                    <CustomerReactionDetailTabs customerReaction={customerReaction} />
                ) : null}
            </Box>
        </Box>
        </>
    );
}
