import { ArrowBackIosNew } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Paper, Select, TextField, Toolbar } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToolButton } from "../../components/ToolButton";
import { addCustomerReaction } from "../../services/customerReactions";
import { INITIAL_FIELD_STATE } from "../../utils/constants";
import { getUniformFieldErrorMessage } from "../../utils/utils";

export default CustomerReactionAddEdit;
export function CustomerReactionAddEdit() {
    const { t } = useTranslation();

    // Input Fields
    const [customerReactionCode, setCustomerReactionCode] = useState({...INITIAL_FIELD_STATE, name: 'CUSTOMERREACTIONCODE', label: t('customerReaction.field.customerReactionCode')});
    const [firstName, setFirstName] = useState({...INITIAL_FIELD_STATE, name: 'FIRSTNAME', label: t('customerReaction.field.firstName')});
    const [lastName, setLastName] = useState({...INITIAL_FIELD_STATE, name: 'LASTNAME', label: t('customerReaction.field.lastName')});
    const [dateOfReceipt, setDateOfReceipt] = useState({...INITIAL_FIELD_STATE, name: 'DATEOFRECEIPT', label: t('customerReaction.field.dateOfReceipt')});
    const [circleOfPeople, setCircleOfPeople] = useState({...INITIAL_FIELD_STATE, name: 'CIRCLEOFPEOPLE', label: t('customerReaction.field.circleOfPeople')});
    const [zipCode, setZipCode] = useState({...INITIAL_FIELD_STATE, name: 'ZIPCODE', label: t('customerReaction.field.zipCode')});
    const [place, setPlace] = useState({...INITIAL_FIELD_STATE, name: 'PLACE', label: t('customerReaction.field.place')});
    const [organisationId, setOrganisationId] = useState({...INITIAL_FIELD_STATE, name: 'ORGANISATION_ID', label: t('customerReaction.field.organisationId')});
    
    // Selects
    const [customerReactionType, setCustomerReactionType] = useState({...INITIAL_FIELD_STATE, name: 'CUSTOMERREACTIONTYPE', label: t('customerReaction.field.customerReactionType'), options: [{optionValue: "In Person", optionLabel: "In Person"}, {optionValue: "Written", optionLabel: "Written"}, {optionValue: "E-Mail", optionLabel: "E-Mail"}, {optionValue: "Telephone", optionLabel: "Telephone"}]});
    const [customerReactionStatus, setCustomerReactionStatus] = useState({...INITIAL_FIELD_STATE, name: 'CUSTOMERREACTIONSTATUS', label: t('customerReaction.field.customerReactionStatus'), value: "OPEN", options: [{optionValue: "OPEN", optionLabel: "OPEN"}, {optionValue: "CLOSED", optionLabel: "CLOSED"}]});

    // Checkboxes
    const [wantAnswer, setWantAnswer] = useState({...INITIAL_FIELD_STATE, value: 'No', name: 'WANTANSWER', label: t('customerReaction.field.wantAnswer')});

    const selectInputStyle = { width: 1/1, marginTop: '16px', marginBottom: '8px' };
    
    const navigate = useNavigate();

    const handleSubmit = (e:any) => {
        e.preventDefault();
        let errorCount = 0;

        if(customerReactionCode.value === "") {
            setCustomerReactionCode({...customerReactionCode, error: true, dirty: true});
            errorCount++;
        }
        if(firstName.value === "") {
            setFirstName({...firstName, error: true, dirty: true});
            errorCount++;
        }
        if(lastName.value === "") {
            setLastName({...lastName, error: true, dirty: true});
            errorCount++;
        }
        if(dateOfReceipt.value === "") {
            setDateOfReceipt({...dateOfReceipt, error: true, dirty: true});
            errorCount++;
        }
        if(circleOfPeople.value === "") {
            setCircleOfPeople({...circleOfPeople, error: true, dirty: true});
            errorCount++;
        }
        if(zipCode.value === "") {
            setZipCode({...zipCode, error: true, dirty: true});
            errorCount++;
        }
        if(place.value === "") {
            setPlace({...place, error: true, dirty: true});
            errorCount++;
        }
        if(organisationId.value === "") {
            setOrganisationId({...organisationId, error: true, dirty: true});
            errorCount++;
        }
        if(customerReactionType.value === "") {
            setCustomerReactionType({...customerReactionType, error: true, dirty: true});
            errorCount++;
        }
        if(customerReactionStatus.value === "") {
            setCustomerReactionStatus({...customerReactionStatus, error: true, dirty: true});
            errorCount++;
        }

        if(!(customerReactionCode.value && firstName.value && lastName.value && dateOfReceipt.value && circleOfPeople.value && customerReactionType.value && customerReactionStatus.value && zipCode.value && place.value && organisationId.value)) {
            document.title = errorCount + " " + t('form.validation.errors') + " - " + t('form.validation.formSubmissionFailed');
            return "";
        }

        const newData = [{
            CUSTOMERREACTIONCODE: customerReactionCode.value,
            FIRSTNAME: firstName.value,
            LASTNAME: lastName.value, 
            DATEOFRECEIPT: Date.parse(dateOfReceipt.value),
            CIRCLEOFPEOPLE: circleOfPeople.value, 
            CUSTOMERREACTIONTYPE: customerReactionType.value, 
            CUSTOMERREACTIONSTATUS: customerReactionStatus.value,
            WANTANSWER: wantAnswer.value,
            ZIPCODE: zipCode.value,
            PLACE: place.value,
            ORGANISATION_ID: organisationId.value
        }];

        addCustomerReaction(newData);
        navigate(`/customerReaction`);
    };
    
    return (
        <Box component="main" sx={{ flexGrow: 1, display: 'flex', p: 2, gap: 2 }}>
            <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }} component={Paper} elevation={2}>
            
                <Toolbar disableGutters variant="dense" sx={{ mt: -1, mb: 2, gap: 1 }}>
                    <ToolButton onClick={() => navigate(`/customerReaction`)} aria-label={t('customerReaction.back')} title={t('customerReaction.back')}>
                        <ArrowBackIosNew fontSize="small" />
                    </ToolButton>
                </Toolbar>

                <Paper component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0, p: 0 }} elevation={0}>

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setCustomerReactionCode({...customerReactionCode, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={customerReactionCode.error} 
                        helperText={customerReactionCode.error && getUniformFieldErrorMessage(customerReactionCode.label)}
                        id={customerReactionCode.name} 
                        label={customerReactionCode.label} 
                        name={customerReactionCode.name} 
                        value={customerReactionCode.value} 
                    />

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setFirstName({...firstName, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={firstName.error} 
                        helperText={firstName.error && getUniformFieldErrorMessage(firstName.label)}
                        id={firstName.name} 
                        label={firstName.label} 
                        name={firstName.name} 
                        value={firstName.value} 
                    />

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setLastName({...lastName, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={lastName.error} 
                        helperText={lastName.error && getUniformFieldErrorMessage(lastName.label)}
                        id={lastName.name} 
                        label={lastName.label} 
                        name={lastName.name} 
                        value={lastName.value} 
                    />

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setDateOfReceipt({...dateOfReceipt, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={dateOfReceipt.error} 
                        helperText={dateOfReceipt.error && getUniformFieldErrorMessage(dateOfReceipt.label)}
                        id={dateOfReceipt.name} 
                        label={dateOfReceipt.label} 
                        name={dateOfReceipt.name} 
                        value={dateOfReceipt.value}
                        type={'date'} 
                    />

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setCircleOfPeople({...circleOfPeople, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={circleOfPeople.error} 
                        helperText={circleOfPeople.error && getUniformFieldErrorMessage(circleOfPeople.label)}
                        id={circleOfPeople.name} 
                        label={circleOfPeople.label} 
                        name={circleOfPeople.name} 
                        value={circleOfPeople.value} 
                    />

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setZipCode({...zipCode, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={zipCode.error} 
                        helperText={zipCode.error && getUniformFieldErrorMessage(zipCode.label)}
                        id={zipCode.name} 
                        label={zipCode.label} 
                        name={zipCode.name} 
                        value={zipCode.value} 
                    />

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setPlace({...place, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={place.error} 
                        helperText={place.error && getUniformFieldErrorMessage(place.label)}
                        id={place.name} 
                        label={place.label} 
                        name={place.name} 
                        value={place.value} 
                    />

                    <TextField 
                        margin="normal" 
                        required 
                        fullWidth 
                        onChange={(e) => setOrganisationId({...organisationId, value: e.target.value, dirty: true, error: e.target.value === ""})} 
                        error={organisationId.error} 
                        helperText={organisationId.error && getUniformFieldErrorMessage(organisationId.label)}
                        id={organisationId.name} 
                        label={organisationId.label} 
                        name={organisationId.name} 
                        value={organisationId.value} 
                    />

                    <FormControl sx={selectInputStyle}>
                        <InputLabel htmlFor={customerReactionType.name+"-select"} id={customerReactionType.name+"-label"} error={customerReactionType.error} >{customerReactionType.label}</InputLabel>
                        <Select
                            SelectDisplayProps={{role:'combobox'}}
                            labelId={customerReactionType.name+"-label"}
                            id={customerReactionType.name}
                            value={customerReactionType.value}
                            onChange={(e: SelectChangeEvent) => setCustomerReactionType({...customerReactionType, value: e.target.value as string, dirty: true, error: e.target.value === ""})}
                            error={customerReactionType.error} 
                            inputProps={{ name: customerReactionType.name, id: customerReactionType.name+"-select" }}
                            sx={{ width: 1/1 }}
                        >
                            {customerReactionType.options.map(({ optionValue, optionLabel }) => (
                                <MenuItem key={optionLabel} value={optionValue}>{optionLabel}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={customerReactionType.error}>{customerReactionType.error && getUniformFieldErrorMessage(customerReactionType.label)}</FormHelperText>
                    </FormControl>

                    <FormControl sx={selectInputStyle}>
                        <InputLabel htmlFor={customerReactionStatus.name+"-select"} id={customerReactionStatus.name+"-label"} error={customerReactionStatus.error} >{customerReactionStatus.label}</InputLabel>
                        <Select
                            SelectDisplayProps={{role:'combobox'}}
                            labelId={customerReactionStatus.name+"-label"}
                            id={customerReactionStatus.name}
                            value={customerReactionStatus.value}
                            onChange={(e: SelectChangeEvent) => setCustomerReactionStatus({...customerReactionStatus, value: e.target.value as string, dirty: true, error: e.target.value === ""})}
                            error={customerReactionStatus.error} 
                            inputProps={{ name: customerReactionStatus.name, id: customerReactionStatus.name+"-select" }}
                            sx={{ width: 1/1 }}
                        >
                            {customerReactionStatus.options.map(({ optionValue, optionLabel }) => (
                                <MenuItem key={optionLabel} value={optionValue}>{optionLabel}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={customerReactionStatus.error}>{customerReactionStatus.error && getUniformFieldErrorMessage(customerReactionStatus.label)}</FormHelperText>
                    </FormControl>

                    <FormControlLabel sx={{width: 1/1}} control={<Checkbox onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWantAnswer({...wantAnswer, value: e.target.checked ? 'Yes' : 'No'})} inputProps={{ name: "WANTANSWER", value:"Yes"}} />} label={t('customerReaction.field.wantAnswer')} />
                    <Button type="submit" color="primary" sx={{ mt: 2, mb: 2 }}>{t('customerReaction.save')}</Button>
                    <Button type="button" color="error" onClick={() => navigate(`/customerReaction`)} sx={{ mt: 2, mb: 2, ml: 2}}>{t('customerReaction.cancel')}</Button>
                </Paper>
            </Box>
        </Box>
    );
}
